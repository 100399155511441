import React, { useContext, useEffect, useState } from 'react';
import { IoMdOptions } from 'react-icons/io';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Row, Col, Card } from 'react-materialize';
import Api from 'src/core/api';
import { Button, Grid, Table, Drawer, Typography, Skeleton, Stack, ButtonBase, List, ListItem, Modal, Box } from '@mui/material';
import Helpers from 'src/core/Helpers';
import AuthContext from 'src/context/auth';
import FilterGlobal from 'src/components/FilterGlobal';
import { ButtonGroup } from 'react-bootstrap';
import HelperContext from 'src/context/helper';
import Util from 'src/core/Util';

export default function Home() {

    const { setViewPedido, setViewHistoryPedido, setViewAlterStatus } = useContext(HelperContext);
    const { user } = useContext(AuthContext);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    async function getPedidos() {
        setLoading(true);
        const response = await Api('get', 'pedidos_vendedores', '', {
            params: {
                page,
                vendedor_id: user.colaborador_id,
                ...params
            }
        });        
        setPedidos(response.data);
        setLoading(false);
    }

    useEffect(() => {
        getPedidos();
    }, [params, search]);

    
    return (
        <div>   
            <FilterGlobal 
                params={params} 
                setParams={setParams} 
                showFilter={showFilter} 
                setShowFilter={setShowFilter}
                getList={getPedidos}
            />          
            <Grid container>
                <Grid item xs={11}>
                    <div className="form-group input-group-sm ">
                        <input 
                            value={search}  
                            onChange={e => setSearch(e.target.value)} 
                            className="form-control" 
                            type="text" 
                            name="search" 
                            placeholder="Buscar por Nº Pedido / Cliente / * "
                        />
                    </div>        
                </Grid>
                <Grid item>
                    <a onClick={() => setShowFilter(true)} style={{ float: 'right' }}>
                        <IoMdOptions color="black" size="1.9em" />
                    </a>
                </Grid>
            </Grid>

            <Grid container>
                <h5 style={{ textAlign: 'center' }}>Todos os Pedidos</h5>
                {loading && 
                    <>
                        <Skeleton variant="text" height={50}/>
                        <Skeleton variant="text" height={50}/>
                        <Skeleton variant="text" height={50}/>
                        <Skeleton variant="text" height={50}/>
                        <Skeleton variant="rectangular" height={118} /> 
                    </>
                }
                {!pedidos.length && 'Nenhum resultado'}
                <List>
                    {pedidos.map((item, index) => (
                        <ListItem key={index} disableGutters>
                            <Card>
                                <Typography>
                                    <strong>Cód. do Pedido:</strong> {item.codigo} <br />
                                    <br />
                                    <strong>Cliente:</strong> {item.cliente.razao_social} <br />
                                    <strong>Endereço:</strong> {item.cliente.logradouro}, {item.cliente.numero} - {item.cliente.bairro} - {item.cliente.cidade} <br />
                                    <strong>Estado/CEP:</strong> {item.cliente.uf} - {item.cliente.cep}<br />
                                    <strong>Vendedor(a):</strong>  {item.vendedor.name}
                                    <br /><br />
                                    <strong>
                                        Data de Abertura: {item.data}
                                    </strong>
                                    <br />
                                    <strong>
                                        Total: {Helpers.converterFloatReal(item.total)}
                                    </strong>
                                    <br />
                                    <strong>Status:</strong> <br />
                                    <span id="tag-status">
                                        {Util.getStatusSpan(item.status)}
                                    </span>
                                    <ButtonGroup variant="text" aria-label="outlined primary button group">
                                        <Button onClick={() => setViewPedido(item.id)}>Visualizar</Button>
                                        
                                        
                                    </ButtonGroup>
                                    
                                </Typography>
                            </Card>
                        </ListItem>
                    ))}
                </List>
                
                <hr />
            </Grid>
            
        </div>
    );
}

