import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import PubSub from "pubsub-js";
import { Col, Container, Row, Table, Spinner } from "react-bootstrap";
import { AiOutlineFilePdf } from "react-icons/ai";
import HelperContext from "src/context/helper";
import Helpers from "src/core/Helpers";
import Api from '../../core/api';
import LoadingScreen from "../LoadingScreen";

export default function PaperOrder() {

    const { viewPedido, setViewPedido } = useContext(HelperContext);
    const [src, setSrc] = useState(null);
    const [loading, setLoading] = useState(false);

    async function getPedido() {
        if(viewPedido) {
            setLoading(true);
            const response = await Api('get',  `relatorios-mobile`, '', {
                params: {
                    tipo: 'pedido',
                    pedido_id: viewPedido,
                }
            }, 'blob');
            const blob = new Blob([response], {type: 'application/pdf'}); //this make the magic
            const blobURL = URL.createObjectURL(blob);
            const link =  document.createElement('a'); //load content in an iframe to print later
            link.href = blobURL;
            const a = document.createElement('a');
            // eslint-disable-next-line no-shadow
            const url = window.URL.createObjectURL(
                new Blob([response], { type: 'application/pdf' })
            );
            window.open(url);
            a.href = url;
            setSrc(a);
            a.remove();
            // link.remove();

            setLoading(false);
        }
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        getPedido();
    }, [viewPedido]);
    
    return (
        <>  
            <Modal
                hideBackdrop
                open={viewPedido ? !true : false}
                onClose={() => setViewPedido('')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <a style={{ position: 'absolute', top: '12px', right: '35px', fontSize: '20px', fontWeight: 'bold' }} href onClick={() => setViewPedido(null)}>X</a>
                    <Row>
                        <Col md={10}>
                            Pedido
                        </Col>
                    </Row>
                    
                    {!loading && src && 
                        <>
                            <iframe
                                src={src}
                                frameBorder="2"
                                width="100%"
                                height="500px"
                                // type="application/pdf"
                            ></iframe>
                        </>
                    }
                </Box>
            </Modal>
        </>
        
        
    );
}