
import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Spinner } from "react-bootstrap";
import Api from '../../core/api';

export default function ReportPdf({ title, endPoint, params, show, setShow }) {

    const [src, setSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    
    async function getData() {
        setLoading(true);
        const response = await Api('get',  `relatorios-mobile`, '', {
            params: {
                tipo: endPoint,
                ...params
            }
        }, 'blob');
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(
            new Blob([response], { type: 'application/pdf' })
        );
        a.href = url;
        window.open(url);
        setSrc(a);
        a.remove();
        setLoading(false);
    }

    useEffect(() => {
        if(show) {
            getData();
        }
    }, [show]);
    
    return (
        <>  
            <Modal
                show={show ? true : false}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" style={{ width: '100%' }}>
                    <Row>
                        <Col md={10}>
                            {title}
                        </Col>
                    </Row>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        {loading && <Spinner animation="border" variant="warning" />}
                    </div>
                    
                    {!loading && src && 
                        <iframe
                            src={src}
                            frameBorder="0"
                            width="100%"
                            height="500px"
                            type="application/pdf"
                        ></iframe>
                    }
            </Modal.Body>
            </Modal>
        </>
    );
}