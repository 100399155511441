import React, { useContext } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { Navbar, NavItem } from 'react-materialize';
import AuthContext from 'src/context/auth';
import { Avatar, Grid } from '@mui/material';

export default function Menu(){


    const { handleLogout, user } = useContext(AuthContext);

    return (
        <>
            {/* <ul id="slide-out" className="sidenav">
                <li>
                    <Link to="/">Painel</Link>
                </li>
                <li>
                    <Link to="/clients">Clientes</Link>
                </li>
                <li>
                    <Link to="/orders">Pedidos</Link>
                </li>
                <li>
                    <Link to="/products">Produtos</Link>
                </li>
                <li><div className="divider"></div></li>
                <li>
                    <Link to="/profile">Perfil</Link>
                </li>
                <li>
                    <Link to="/help">Ajuda</Link>
                </li>
            </ul>
            
            <a href="#" data-target="slide-out" className="sidenav-trigger" style={{ padding: '12px' }}>
                <GiHamburgerMenu size="32px" />
            </a> */}
            <Navbar
                alignLinks="right"
                brand={<a className="brand-logo" href="#">
                    <img src="images/logo.png" width="80px"/>
                    
                </a>}
                id="mobile-nav"
                menuIcon={<GiHamburgerMenu />}
                options={{
                    draggable: true,
                    edge: 'left',
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                    preventScrolling: true
                }}
            >
                <NavItem style={{ margin: '25px 0px' }}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Avatar src="https://cdn3.iconfinder.com/data/icons/avatars-round-flat/33/avat-01-512.png" />
                        </Grid>
                        <Grid item xs={9} style={{ lineHeight: '20px' }}>
                            Seja Bem-vindo(a)! <br />
                            <strong>{user.name}</strong>
                        </Grid>
                    </Grid>
                    
                    <br />
                </NavItem>
                
                <Link  to="/">
                    <NavItem>
                        Painel
                    </NavItem>
                </Link>
                <Link to="/visita">
                    <NavItem>
                        Visitas
                    </NavItem>
                </Link>
                <Link to="/clients">
                    <NavItem>
                        Clientes
                    </NavItem>
                </Link>
                <Link to="/orders">
                    <NavItem>
                        Pedidos
                    </NavItem>
                </Link>
                <Link to="/products">
                    <NavItem>
                        Produtos
                    </NavItem>
                </Link>
                <Link to="/relatorios">
                    <NavItem>
                        Relatórios
                    </NavItem>
                </Link>
                <Link to="/table-prices">
                    <NavItem>
                        Tabela de Preços
                    </NavItem>
                </Link>
                <NavItem><div className="divider"></div></NavItem>
                <Link to="/profile">
                    <NavItem>
                        Perfil
                    </NavItem>
                </Link>
                <Link to="/help">
                    <NavItem>
                        Ajuda
                    </NavItem>
                </Link>
                <NavItem onClick={() => handleLogout()}>
                    <a href>Sair</a>
                </NavItem>
            </Navbar>
        </>
    );
}