import React from 'react';
import { Button, Grid,Drawer, TextField, MenuItem, Select } from '@mui/material';


export default function FilterGlobal(props) {

    return (
        <div>             
            <Drawer
                style={{ width: '80%' }}
                anchor="right"
                open={props.showFilter}
                onClose={() => props.setShowFilter(false)}
            >
                <a href onClick={() => props.setShowFilter(false)} style={{ position: 'absolute', top: '10px', right: '15px', fontSize: '1.5em' }}>X</a>
                <form style={{ width: '90%', margin: '0 auto' }}>  

                    <Grid>
                        <h5>Filtros</h5>
                        <Grid md={2} xs={12}>
                            <div className="form-group input-group-sm ">
                                <label>Data Inicial</label>
                                <TextField 
                                    className="form-control" 
                                    type="date" 
                                    name="data_inicio" 
                                    margin="normal"
                                    fullWidth
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} 
                                />
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <div className="form-group input-group-sm ">
                                <label>Data Final</label>
                                <TextField 
                                    margin="normal"
                                    fullWidth
                                    className="form-control"                                    
                                    type="date" 
                                    name="data_fim" 
                                    
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} 
                                />
                            </div>
                        </Grid>
                        <Grid xs={12}>
                        <label>Status</label>
                            <Select
                                labelId="status"
                                id="status"
                                name="status" 
                                fullWidth
                                margin="normal"
                                value={props.params.status}
                                label="Status"
                                onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            >
                                <MenuItem value='open'>Em Aberto</MenuItem>
                                <MenuItem value='waiting'>Aguardando</MenuItem>
                                <MenuItem value='pending'>Pendente</MenuItem>
                                <MenuItem value='approved'>Aprovado</MenuItem>
                                <MenuItem value='production'>Em Produção</MenuItem>
                                <MenuItem value='travel'>Em Viagem</MenuItem>
                                <MenuItem value='finished'>Finalizado</MenuItem>
                                <MenuItem value='cancel'>Cancelado</MenuItem>
                            </Select>
                        </Grid>
                        <Grid xs={12}>
                            <div  style={{ marginTop: 12 }}>
                                <Button variant="contained" color={props.periodo === 'today' ? 'success' : 'info'} size="sm" onClick={() => props.setParams({...props.params, ...{periodo: 'today'}})}>Hoje</Button>
                                <Button variant="contained" color={props.periodo === 'yesterday' ? 'success' : 'info'} size="sm" onClick={() => props.setParams({...props.params, ...{periodo: 'yesterday'}})}>Ontem</Button>
                                <Button variant="contained" color={props.periodo === 'month' ? 'success' : 'info'} size="sm" onClick={() => props.setParams({...props.params, ...{periodo: 'month'}})}>Mês</Button>
                                <Button variant="contained" color={props.periodo === 'total' ? 'success' : 'info'} size="sm" onClick={() => props.setParams({...props.params, ...{periodo: 'total'}})}>Geral</Button>
                                <a style={{ padding: '10px', width: '100%', background: 'red', color: '#FFF', borderRadius: '5px' }} href size="sm" onClick={() => props.setParams({...props.params, ...{periodo: ''}})}>X</a>
                            </div>
                        </Grid>
                        
                    </Grid>
                    <Grid>
                        <Grid xs={12}>
                            <div className="form-group input-group-sm ">
                                <label>Buscar por Nº Pedido / Cliente / * </label>
                                <TextField 
                                    margin="normal"
                                    fullWidth  
                                    className="form-control" 
                                    type="text" 
                                    name="search" 
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                />
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <div className="form-group input-group-sm ">
                                <label>Bairro</label>
                                <TextField 
                                    margin="normal"
                                    fullWidth 
                                    className="form-control" 
                                    type="text" 
                                    name="bairro" 
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                />
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <div className="form-group input-group-sm ">
                                <label>Cidade</label>
                                <TextField 
                                    margin="normal"
                                    fullWidth  
                                    className="form-control" 
                                    type="text" 
                                    name="cidade" 
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                />
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <div className="form-group input-group-sm ">
                                <label>Estado</label>
                                <TextField 
                                    margin="normal"
                                    fullWidth  
                                    className="form-control" 
                                    type="text" 
                                    name="estado" 
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                />
                            </div>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <div className="form-group">
                                <br />
                                <Button 
                                    variant="contained"
                                    onClick={() => {
                                        props.setShowFilter(false);
                                        props.getList();
                                    }} 
                                    type="button" 
                                    style={{ marginTop: '5px' }} 
                                    className="btn-sm btn btn-info">Filtrar</Button>
                                &nbsp;
                                <Button onClick={() => {
                                    props.setShowFilter(false);
                                    props.setParams({});
                                }} 
                                variant="contained"
                                type="button" style={{ marginTop: '5px' }} 
                                    className="btn btn-warning btn-sm ">Limpar</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <br /><br />
                </form>
            </Drawer>
        </div>
    );
}

FilterGlobal.defaultProps = {
    params: {
        data_inicio: '',
        data_fim: '',
        search: ''
    },

};