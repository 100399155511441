import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Form, FormControl, InputGroup, Button as Btn, FormGroup } from 'react-bootstrap';
import { Select as SelectMat } from 'react-materialize';
import IntlCurrencyInput from "react-intl-currency-input"
import { TextField } from '@mui/material';

export function InputText(props) {
    return (
        <>
            <TextField
                fullWidth
                label={props.label}
                {...props} 
                {...props.field} 
                margin="normal"
            />
            <div><span className="error">{props.error}</span></div>
        </>
    );
}

InputText.defaultProps = {
    error: null,
    errors: null,
}

const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
};

export function InputTextFormat(props) {

    // function cardExpiry(value) {
    //     props.form.setFieldValue(props.field.name, value);
    // }
    return (
        <FormGroup>
            {props.label && 
                <Form.Label className={props.required && `required`} htmlFor={props.id}>{props.label}</Form.Label> 
            }
            <IntlCurrencyInput 
                currency="BRL" 
                config={currencyConfig} 
                onChange={e => console.log(e)} 
                {...props} 
                {...props.field} 
                value={parseFloat(props.field.value)} 
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`}
                onChange={(e, value, maskedValue) => {
                    if(props.handleChange) {
                        props.handleChange(value);
                    } else {
                        props.form.setFieldValue(props.field.name, value)
                    }
                }}
            />
            {/* <FormControl {...props} {...props.field}  className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} /> */}
            {/* <CurrencyFormat 
                format={cardExpiry}
                prefix={'R$ '} 
                {...props} {...props.field}  className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`}
            /> */}

            <div><span className="error">{props.error}</span></div>
        </FormGroup>
    );
}

InputTextFormat.defaultProps = {
    handleChange: null,
}

export function InputMask(props) {
    return (
        <FormGroup>
            {props.label && 
                <Form.Label className={props.required && `required`} htmlFor={props.id}>{props.label}</Form.Label> 
            }
            <NumberFormat 
                {...props} 
                {...props.field} 
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`}
                format={props.mask}
                mask=""
            />
            <div><span className="error">{props.error}</span></div>
        </FormGroup>
    );
    
}
InputMask.defaultProps = {
    mask: '',
};

export function InputRadioCheckbox(props) {
    return (
        <>
            <br />
            <InputGroup>
                {/* <Form.Label className={props.required && `required`}  htmlFor={props.id}>{props.label}</Form.Label> */}
                <Form.Check 
                    {...props} 
                    {...props.field} 
                    className={props.required && `required`}
                    checked={props.field.value}
                    onChange={e => {
                        props.form.setFieldValue(props.field.name, e.target.checked);
                    }}
                />
                <div><span className="error">{props.error}</span></div>
            </InputGroup>
        </>
    );
}

export function Button(props) {
    return (
        <Btn {...props}>{props.name}</Btn>
    );
}

export function Select(props) {
    return (
        <>
            {/* <FormGroup > */}
                {/* <Form.Label className={props.required && `required`}  htmlFor={props.id}>{props.label}</Form.Label> */}
                {/* <Form.Control 
                    as="select" 
                    custom  
                    {...props} 
                    {...props.field} 
                    onChange={e => {
                        if(props.handleChange) {
                            props.handleChange(e.target.value);
                        } else {
                            props.form.setFieldValue(props.field.name, e.target.value)
                        }
                    }}
                >
                    <option value="">{props.placeholder}</option>
                    {props.list.map(item => (
                        <option value={item.value}>{item.label}</option>
                    ))}
                </Form.Control> */}
                <SelectMat
                    id="Select-31"
                    style={{ width: '100%' }}
                    multiple={false}
                    {...props} 
                    {...props.field} 
                    onChange={e => {
                        if(props.handleChange) {
                            props.handleChange(e.target.value);
                        } else {
                            props.form.setFieldValue(props.field.name, e.target.value)
                        }
                    }}
                    options={{
                        classes: '',
                        dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250
                        }
                    }}
                    value=""
                    >
                        <option value="">{props.placeholder}</option>
                        {props.list.map(item => (
                            <option value={item.value}>{item.label}</option>
                        ))}
                    </SelectMat>
            {/* </FormGroup> */}
            <div><span className="error">{props.error}</span></div>
        </>
    );
}

Select.defaultProps = {
    handleChange: null,
}

export function FileUpload(props) {
    return (
        <FormGroup>
            {props.label && 
                <Form.Label className={props.required && `required`} htmlFor={props.id}>{props.label}</Form.Label> 
            }
            <FormControl 
                {...props} 
                {...props.field}  
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} 
                onChange={e => props.handleFile(e)}
            />
            <div><span className="error">{props.error}</span></div>
        </FormGroup>
    );
}

export function InputTextarea(props) {
    return (
        <FormGroup>
            {props.label && 
                <Form.Label className={props.required && `required`} htmlFor={props.id}>{props.label}</Form.Label> 
            }
            <FormControl 
                as="textarea" 
                {...props} 
                {...props.field}  
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} 
            />
            <div><span className="error">{props.error}</span></div>
        </FormGroup>
    );
}

export function InputFile(props) {

    const [fileName, setFileName] = useState('');

    function handleFile(e) {
        if(e.target.files.length) {
            const file = e.currentTarget.files[0];
            setFileName(file.name);
            if(props.loadFile) {
                props.loadFile(file);
            }
            
        } else {
            setFileName('');
        }
    }

    return (
        <FormGroup>
            {props.label && 
                <Form.Label className={props.required && `required`} htmlFor={props.id}>{props.label}</Form.Label> 
            }
            <FormControl 
                {...props} 
                {...props.field}  
                onChange={handleFile}
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} />
            <div><span className="error">{props.error}</span></div>
            <div><span>{fileName}</span></div>
        </FormGroup>
    );
}

InputFile.defaultProps = {
    error: null,
    errors: null,
    loadFile: null,
}