import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-materialize';
import { styled } from '@mui/material/styles';
import Pagination from '../Pagination';
import { GrAdd } from 'react-icons/gr';
import Api from '../../core/api';
import Form from '../Form';
import Grid from '../Grid';
import InputSearch from '../FormHelper/InputSearch';
import Message from '../../core/Message';
import useDebounce from 'src/components/useDebounce';
import { AppBar, Fab, Toolbar } from '@mui/material';
export default function Crud(props) {   

    const [view, setView] = useState('list');
    const [data, setData] = useState(props.emptyObject);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const debouncedSearchTerm = useDebounce(search, 500);

    const StyledFab = styled(Fab)({
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
      });

    async function handleSubmit (values, objects) {
        let msg = 'Registro Salvo com Sucesso';

        if(values.id) {
            msg = 'Registro Atualizado com Sucesso';
             await Api('put', props.endPoint, `/${values.id}`, values);
        } else {
            const response = await Api('post', props.endPoint, '', values);
            setData(response.data)
        }
        // objects.setSubmi
        Message.success(msg);
        loadData();
        if(!props.saveContinueForm) {
            setView('list');
        } else {
            setView('edit');
        }
    }

    function handleNew() {
        setData(props.emptyObject);
        setView('new');
    }

    async function handleEdit(item) {
        const response = await Api('get', props.endPoint, `/${item.id}`, {});
        setData(response.data);
        setView('edit');
    }

    async function handleDelete(item) {
        const check = await Message.confirmation("Deseja deletar este registro?");
        if(check) {
            await Api('delete', props.endPoint, `/${item.id}`, {});
            loadData();
            Message.success("Registro deletado com sucesso.");
        }
    }

    function handleList(item) {
        setView('list');
    }

    async function loadData() {
        let params = {
            page: page
        };
        
        if(props.searchFieldName && search) {
            params[props.searchFieldName] = search;
        }
        
        const response = await Api('get', props.endPoint, '', {
            params,
            ...props.paramsSearch
        });
        //setList(response.data);
        setList(response.data);
        setPagination(response);
    }

    useEffect(() => {
        loadData();
    }, [page]);

    useEffect(() => {
        if(search === '') {
            loadData();
        }
    }, [search]);

    useEffect(() => {
        if(debouncedSearchTerm) {
            loadData();
        }
    }, [debouncedSearchTerm]);
    
    return (
        <div id="crud">
            <div id="content-title">
                <h5>
                    {props.title} 
                </h5>
            </div>
            <div id="content">
                {view === 'list' ?
                    <Row>
                        <Col s={12}>
                            <InputSearch 
                                value={search} 
                                handleText={value => setSearch(value)} 
                                loadData={loadData}
                            />
                        </Col>
                        
                    </Row>
                    : null
                }
                {view === 'list' && 
                    <>
                        
                        <Grid 
                            {...props}
                            list={list} 
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />

                        <Pagination
                            changePage={data => setPage(data.page)} 
                            data={pagination}                            
                        />
                        
                        {props.enableBtnNew && 
                            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                                <Toolbar>
                                    <StyledFab onClick={handleNew} color="red" aria-label="add">
                                        <GrAdd color="#FFF" size="1.8em" />
                                    </StyledFab>
                                </Toolbar>
                            </AppBar>
                        }
                    </>
                }
                
                {view === 'new' || view === 'edit' ? 
                    <Form 
                        {...props}
                        view={view}
                        emptyObject={data} 
                        handleEdit={handleEdit}
                        handleSubmit={handleSubmit}
                        handleCancel={handleList}
                    /> : null}
           </div>
            
        </div>
    );

}

Crud.defaultProps = {
    enableBtnNew: true,
    saveContinueForm: false,
    paramsSearch: {}
};