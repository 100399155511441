
import React, { useEffect, useState } from 'react';
import { CardMedia, Typography, Card } from '@mui/material';
import { Row, Col, Table } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText, InputTextarea, InputTextFormat, Select } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';

const FormWrapper = ({ Field, ErrorMessage, values }) => {
    const [listProdutosItens, setListProdutosItens] = useState([]);
    
    async function getProdutosItens() {
        if(values.id) {
            const response = await Api('get', `produtos-montagem/list-produtos/${values.id}`, '', {});        
            setListProdutosItens(response.data);
        }
    }

    useEffect(() => {
        getProdutosItens();
    }, [values.id]);

    return (
        <>
            <Row>
                <Col s={12} l={12}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col s={12} l={12}>
                    <Field 
                        required
                        id="titulo"
                        label="Título"
                        name="titulo"
                        disabled
                        component={InputText}
                        error={<ErrorMessage name="titulo" />}
                    />
                </Col>
            </Row>   
            <Row>
                <Col s={12} l={12}>
                    <Field 
                    disabled
                        required
                        id="codigo"
                        label="Código de Barras"
                        name="codigo"
                        component={InputText}
                        error={<ErrorMessage name="codigo" />}
                    />
                </Col>
                <Col s={12} m={12}>
                    <Field
                        disabled
                        id="codigo_geral"
                        label="Código Universal"
                        name="codigo_geral"
                        component={InputText}
                        error={<ErrorMessage name="codigo_geral" />}
                    />
                </Col>
                <Col s={12} m={12}>
                    <Field 
                        required
                        disabled
                        id="tipo"
                        label="Tipo"
                        name="tipo"
                        component={Select}
                        list={[
                            { value: 'produto', label: 'Produto' },
                            { value: 'semi_produto', label: 'Semi-Produto' },
                            { value: 'materia', label: 'Matéria Prima' },
                        ]}
                        error={<ErrorMessage name="tipo" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12}>
                    <Field 
                        id="descricao"
                        disabled
                        label="Descrição"
                        name="descricao"
                        component={InputTextarea}
                        error={<ErrorMessage name="descricao" />}
                    />
                </Col>
            </Row>  
            <Row>
                <Col s={12} m={12}>
                    <Field 
                        required
                        disabled
                        id="valor"
                        label="Valor"
                        name="valor"
                        component={InputTextFormat}
                        error={<ErrorMessage name="valor" />}
                    />
                </Col>
                <Col s={12} m={12}>
                    <Field 
                        id="custo"
                        disabled
                        label="Custo"
                        name="custo"
                        component={InputTextFormat}
                        error={<ErrorMessage name="custo" />}
                    />
                </Col>
                {/* <Col s={12} m={12}>
                    <Field 
                        disabled
                        id="peso"
                        label="Peso"
                        name="peso"
                        component={InputText}
                        error={<ErrorMessage name="peso" />}
                    />
                </Col> */}
                <Col s={12} m={12}>
                    <Field 
                        required
                        disabled
                        id="quantidade"
                        label="Quantidade"
                        name="quantidade"
                        component={InputText}
                        error={<ErrorMessage name="quantidade" />}
                    />
                </Col>
                <Col s={12} m={12}>
                    <Field 
                        required
                        disabled
                        id="tipo_unidade"
                        label="Tipo de Unidade"
                        name="tipo_unidade"
                        component={Select}
                        list={[
                            { value: 'unid', label: 'Unidade' },
                            { value: 'kg', label: 'Kg' },
                        ]}
                        error={<ErrorMessage name="tipo_unidade" />}
                    />
                </Col>
            </Row> 
            {/* {JSON.stringify(values, null, 2)} */}
            <Row>
                <Col s={12} m={12}>
                    <label>Fornecedor</label>
                    <Field 
                        required
                        disabled
                        endPoint="fornecedores/options"
                        id="fornecedor_id"
                        label="Selecione o Fornecedor"
                        name="fornecedor_id"
                        component={SelectAutoComplete}
                        error={<ErrorMessage name="fornecedor_id" />}
                    />
                </Col>
            </Row>
            <hr />
            {values.id && 
                <>
                    <Row>
                        <Col s={12} md={12}>
                            <h6>Composição dos Items Abaixo</h6>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Título</th>
                                        <th>Tipo Unidade</th>
                                        <th>Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listProdutosItens.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.produto_montagem.titulo}</td>
                                            <td>{item.produto.tipo_unidade}</td>
                                            <td>{item.quantidade}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
            }  
        </>
    );
}

export default function Produtos() {
    
    return (
        <Crud 
            title="Produtos"
            endPoint="produtos"
            emptyObject={{
                titulo: '',
                valor: '',
                tipo: 'produto',
                quantidade: '',
                produto_id: '',
                fornecedor_id: '',
            }}
            // enableBtnEdit={false}
            enableBtnDelete={false}
            enableBtnSave={false}
            enableBtnNew={false}
            enableBtnEdit={false}
            fields={[
                { name: 'id', label: 'Dados', classBody: 'min-width' },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td>
                        <img src={item.image} width="85px" />
                    </td>
                    <td>    
                        <Typography>
                            <strong>
                                {item.titulo}
                            </strong>
                        </Typography>
                        {/* <Typography>
                            <strong>
                                Preço: 
                            </strong>
                            {Helpers.converterFloatReal(item.valor)}
                            <br />
                            Quantidade: {item.quantidade || 0}
                        </Typography> */}
                    </td>
                </>
            )}
            validation={(yup) => {
                return {
                    titulo: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}

