import React from 'react';

export default function Footer() {   
    return (
        <footer style={{ padding: '10px', textAlign: 'center', borderTop: '1px solid #999', marginTop: '10px' }}>
            <div className="pull-right">
                Parlumin <a href="">@</a>
            </div>
            <div className="clearfix"></div>
        </footer>
    );
}

