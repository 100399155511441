import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Api from 'src/core/api';
import Pagination from 'src/components/Pagination';

import ReportPdf from 'src/components/ReportPdf';

import FilterGlobal2 from 'src/components/FilterGlobal2';
import AuthContext from 'src/context/auth';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

export default function ProdutosMaisVendidos() {

    const { user } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({});
    
    async function getCLientes() {
        const response = await Api('get', 'maisvendidos', '', {
            params: {
                page: page,
                vendedor_id: user.colaborador_id,
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    useEffect(() => {
        getCLientes();
    }, [page, params]);


    return (
        <div className="" role="main" style={{ padding: '10px' }}>
            <ReportPdf 
                title="Produtos Mais Vendidos"
                endPoint="produtosMaisVendidos"
                params={{
                    pedidos: selected,
                    vendedor_id: user.colaborador_id,
                    ...params,
                }}
                show={showPdf}
                setShow={setShowPdf}
            />

            <h5>
                <Link to="relatorios">
                    <Button>
                        <BiArrowBack size="30px" />
                    </Button>
                </Link>
                Produtos Mais Vendidos
            </h5>
            
            <Grid container>
                <h4>Vendas</h4>
                <Grid item xs={12}>
                    <Button
                        style={{ float: 'right' }} 
                        size="small"
                        variant="contained" 
                        onClick={() => setShowPdf(true)}>
                            Gerar PDF
                    </Button>
                </Grid>
            </Grid>
            
            <FilterGlobal2 
                params={params} 
                setParams={setParams} 
                setSelected={setSelected} 
                list={pedidos} 
                // isEnableFieldSearch={true}
                isEnableFieldStatus={false}
                isEnableFieldCodigo={false}
                isEnableFieldAllPedidos={true}
                isEnableFieldAscDesc={true}

            />
            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                    <tr>
                        <th>
                            Dados
                        </th>
                    </tr>
                    {pedidos.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td className="min-width">
                                <img src={item.produto.image || ''} width="110px" />
                                <br />
                                {item.produto.titulo}
                                <br />
                                Quantidade: {item.quantidade}
                            </td>
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
        </div>
    );
}
