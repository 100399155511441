import { Button, Checkbox, Drawer, FormControlLabel, FormGroup, Grid, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';


export default function FilterGlobal2(props) {
    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    return (
        <>
            <Button variant="contained" size="small" onClick={toggleDrawer(true)}>Filtrar</Button>
            <br /><br />
            <Drawer
                anchor='right'
                open={open}
                onClose={toggleDrawer(false)}
            >

                <Grid container>
                    <Grid xs={12}>
                        <Button variant="contained" size="small"  onClick={toggleDrawer(false)}>Voltar</Button>
                    </Grid>
                    {props.isEnableFieldSituacao && 
                        <Grid xs={12}>
                            <Select
                                labelId="situacao"
                                id="situacao"
                                name="situacao" 
                                fullWidth
                                value={props.params.status}
                                label="Situação"
                                onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            >
                                <MenuItem value='1'>Ativo</MenuItem>
                                <MenuItem value='0'>Inativo</MenuItem>
                            </Select>
                        </Grid> 
                    }
                    
                    {props.isEnableFieldStatus && 
                        <Grid xs={12}>
                            <label>Status</label>
                            <Select
                                labelId="status"
                                id="status"
                                name="status" 
                                fullWidth
                                value={props.params.status}
                                label="Status"
                                onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            >
                                <MenuItem value='open'>Em Aberto</MenuItem>
                                <MenuItem value='waiting'>Aguardando</MenuItem>
                                <MenuItem value='pending'>Pendente</MenuItem>
                                <MenuItem value='approved'>Aprovado</MenuItem>
                                <MenuItem value='production'>Em Produção</MenuItem>
                                <MenuItem value='travel'>Em Viagem</MenuItem>
                                <MenuItem value='finished'>Finalizado</MenuItem>
                                <MenuItem value='cancel'>Cancelado</MenuItem>
                            </Select>
                        </Grid>
                    }
                    {props.isEnableFieldData && 
                        <>
                            <Grid xs={12}>
                                <label>Data Início</label>
                                <TextField
                                    fullWidth
                                    id="data_inicio"
                                    name="data_inicio"
                                    margin="normal"
                                    value={props.params.data_inicio} 
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                    type="date" 
                                />
                            </Grid>
                            <Grid xs={12}>
                                <label>Data Final</label>
                                <TextField
                                    fullWidth
                                    id="data_fim"
                                    name="data_fim"
                                    margin="normal"
                                    value={props.params.data_fim} 
                                    onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                    type="date" 
                                />
                            </Grid>
                        </>
                    }
                    {props.isEnableFieldSearch && 
                        <Grid xs={12}>
                            <TextField
                                fullWidth
                                id="search"
                                name="search"
                                margin="normal"
                                value={props.params.search} 
                                onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                label="Buscar por Cliente / CNPJ / CPF / Endereço / Vendedor"
                            />
                        </Grid>
                    }
                    <Grid xs={12}>
                        <TextField
                            fullWidth
                            id="cnpj"
                            name="cnpj"
                            margin="normal"
                            value={props.params.cnpj} 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            label="CNPJ"
                        />
                    </Grid>
                
                    <Grid xs={12}>
                        <TextField
                            fullWidth
                            id="bairro"
                            name="bairro"
                            margin="normal"
                            value={props.params.bairro} 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            label="Bairro"
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            fullWidth
                            id="cidade"
                            name="cidade"
                            margin="normal"
                            value={props.params.cidade} 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            label="Cidade"
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            fullWidth
                            id="estado"
                            name="estado"
                            margin="normal"
                            value={props.params.estado} 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            label="Estado"
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            fullWidth
                            id="vendedor"
                            name="vendedor"
                            margin="normal"
                            value={props.params.vendedor} 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            label="Vendedor"
                        />
                    </Grid>
                
                    {props.isEnableFieldCodigo &&
                        <Grid xs={12}>
                            <TextField
                                fullWidth
                                id="codigo"
                                name="codigo"
                                margin="normal"
                                value={props.params.codigo} 
                                onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                                label="Código do Pedido"
                            />
                        </Grid>
                    }
                    {props.isEnableFieldAllPedidos && 
                        <Grid xs={12}>
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox 
                                        name="all_pedidos"
                                        onChange={e => {
                                            if(e.target.checked) {
                                                let lis = props.list.map(item => item.id);
                                                props.setSelected([...lis]);
                                            } else {
                                                props.setSelected([]);
                                            }
                                            props.setParams({...props.params, ...{ [e.target.name]: e.target.checked }});
                                        }}
                                        checked={props.params.all_clientes}
                                    />} 
                                    label="Todos os Pedidos?" />
                            </FormGroup>
                        </Grid>
                    }
                    {props.isEnableFieldAscDesc && 
                        <Grid xs={12}>
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox 
                                        name="ordem"
                                        onChange={e => {
                                            if(e.target.checked) {
                                                let lis = props.list.map(item => item.id);
                                                props.setSelected([...lis]);
                                            } else {
                                                props.setSelected([]);
                                            }
                                            props.setParams({...props.params, ...{ [e.target.name]: e.target.checked }});
                                        }}
                                        checked={props.params.ordem}
                                    />} 
                                    label="Decrescente" />
                            </FormGroup>
                        </Grid>
                    }
                </Grid>
            </Drawer>
            
            
        </>
    );
}

FilterGlobal2.defaultProps = {
    params: {
        data_inicio: '',
        data_fim: '',
        cnpj: '',
        all_pedidos: false
    },
    isEnableFieldStatus: true,
    isEnableFieldData: true,
    isEnableFieldSearch: false,
    isEnableFieldCodigo: true,
    isEnableFieldAllPedidos: true,
    isEnableFieldAscDesc: false,
    isEnableFieldSituacao: false,
};