import React, { createContext, useEffect, useState } from 'react';
import Util from 'src/core/Util';
import Api from 'src/core/api';

const AuthContext = createContext({ list: [] });

export const AuthProvider = ({ children }) => {

    const [isLogged, setIsLogged] = useState(false);
    const [user, setUser] = useState({ name: '', email: '' });

    async function handleLogout() {
        Util.removeToken();
        setIsLogged(false);
        Util.redirect();
    }

    async function getUser() { 
        const response = await Api('post', 'me', '', {});
        setUser(response);
    }

    useEffect(() => {
        if(Util.getToken()) {
            setIsLogged(true);
            getUser();
        }
    }, []);

    return (
        <AuthContext.Provider value={{ 
            isLogged,
            setIsLogged,
            handleLogout,
            user,
            setUser
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
