import React, { useContext } from 'react';
import { Redirect, Route } from "react-router-dom";
import AuthContext from 'src/context/auth';
import Body from 'src/layouts/Body';
import Footer from 'src/layouts/Footer';
import Login from 'src/pages/Login';

const RouterPrivate = (props) => {
    
    const { isLogged } = useContext(AuthContext);
    

    if(props.isPrivate && !isLogged) {
        return <Redirect to="login" />;
    }

    if(props.path === '/login' && isLogged) {
        return <Redirect to="/" />;
    }

    if(props.path === '/login') {
        return <Login />;
    }

    return (
        <>
            <Body>
                <div className="right_col" style={{ minHeight: '100vh' }} role="main">
                    <Route path={props.path} component={props.component} />
                </div>
            </Body>
            <Footer />
        </>
    )
}

export default RouterPrivate;