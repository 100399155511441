import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import Crud from 'src/components/Crud';
import { InputText, InputTextarea } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import AuthContext from 'src/context/auth';

import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';

const FormWrapper = ({ view, Field, handleEdit, ErrorMessage, setFieldValue, values }) => {
    
    const [list, setList] = useState([]);
    const { user } = useContext(AuthContext)
    const [position, setPosition] = useState({
        lat: '',
        lng: ''
    });

    async function addClient(cliente) {
        setFieldValue('cliente_id', cliente.value);
    }

    async function showPosition(position){
        await setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
    }

    async function checkInAux(lat, lng) {
        
        // console.log(navigator.geolocation);
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            Message.error("Navegador não suporta Geolocalização");
        }
        const check = await Message.confirmation("Deseja fazer Check-in?");
        if(check) {
            checkIn(lat, lng);
        }
    }
    async function checkIn(lat, lng) {        
        if(view === 'edit') {
            await Api('post', 'visita-check', '', {
                id: values.id,
                type: 'checkin',
                lat_check_in: lat,
                lng_check_in: lng,
            });
            
            handleEdit({...{ id: values.id} });
            Message.success("Check-in Realizado com Sucesso.")
        } else {
            
            let data = {...values};
            data.lat_check_in = lat;
            data.lng_check_in = lng;
            const response = await Api('post', 'visita', '', data);
            Message.success("Check-in Realizado com Sucesso.")
            handleEdit({...{ id: response.data.id} });
            
        }
    }

    async function checkOut() {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            Message.error("Navegador não suporta Geolocalização");
        }

        const check = await Message.confirmation("Deseja fazer Check-out?");
        if(check) {
            if(values.id) {
                await Api('post', 'visita-check', '', {
                    id: values.id,
                    type: 'checkout',
                    lat_check_out: position.lat,
                    lng_check_out: position.lng,
                });
                handleEdit({...{ id: values.id} });
                Message.success("Check-out Realizado com Sucesso.");
                console.log(values);
            }
        }
    }

    useEffect(() => {
        
    }, []);

    return (
        <>
            <Grid container>
                {view === 'new' ? 
                    <Grid item xs={12}>
                        <Field
                            label="Clientes"
                            name="cliente_id"
                            id="cliente_id"
                            endPoint="clientes/options"
                            params={{
                                vendedor_id: user.colaborador_id,
                                ativo: 1,
                            }}
                            handleChange={addClient}
                            component={SelectAutoComplete}
                        />
                    </Grid>
                : <Grid container item>
                    <Grid item xs={12}>
                        <strong>Cliente</strong>:<br /> {values.cliente.razao_social || ''} <br />
                    </Grid>
                    <Grid item xs={12}>
                        <strong>Fantasia</strong>: {values.cliente.nome_fantasia} <br />
                    </Grid>
                    <Grid item xs={12}>
                        <strong>CNPJ</strong>: {values.cliente.cnpj} <br />
                    </Grid>
                    <Grid item xs={12}>
                        <strong>E-mail</strong>: {values.cliente.email} <br />
                    </Grid>
                </Grid>}
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Field 
                        required
                        type="date"
                        // disabled={view === 'new' ? false : true}
                        disabled
                        id="data_visita"
                        label="Data da Visita"
                        name="data_visita"
                        component={InputText}
                        error={<ErrorMessage name="data_visita" />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field 
                        required
                        // disabled={view === 'new' ? false : true}
                        disabled
                        type="time"
                        id="hora_visita"
                        label="Hora da Visita"
                        name="hora_visita"
                        component={InputText}
                        error={<ErrorMessage name="hora_visita" />}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <Field 
                        required
                        disabled
                        id="lat"
                        label="Latitude"
                        name="lat"
                        component={InputText}
                        error={<ErrorMessage name="lat" />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field 
                        required
                        disabled
                        id="lng"
                        label="Longitude"
                        name="lng"
                        component={InputText}
                        error={<ErrorMessage name="lng" />}
                    />
                </Grid> */}
            </Grid> 
            <Grid container>
                <Grid item xs={12}>
                    <Field 
                        required
                        disabled={values.lat_check_in && values.lat_check_out ? true : false}
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        component={InputTextarea}
                        error={<ErrorMessage name="observacao" />}
                    />
                </Grid>
            </Grid>   
            <Grid container spacing>
                {!values.lat_check_in &&
                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                if (navigator.geolocation) {
                                    navigator.geolocation.getCurrentPosition((position) => {
                                        checkInAux(position.coords.latitude, position.coords.longitude)
                                    });
                                } else {
                                    Message.error("Navegador não suporta Geolocalização");
                                }
                                
                            }}
                            style={{ width: '100%' }}
                            variant="contained"
                            color="primary"
                        >
                            Check-in
                        </Button>
                    </Grid>
                }
                 {values.lat_check_in && !values.lat_check_out &&
                    <Grid item xs={12}>
                        <Button
                            onClick={() => checkOut()}
                            style={{ width: '100%' }}
                            variant="contained"
                            color="info"
                        >
                            Check-out
                        </Button>
                    </Grid>
                }
            </Grid>           
        </>
    );
}

export default function Agendamentos() {

    const [message, setMessage] = useState('');
    const [position, setPosition] = useState({
        lat: '',
        lng: ''
    });

    function showPosition(position){
        setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
        // x.innerHTML="Latitude: " + position.coords.latitude +
        // "<br>Longitude: " + position.coords.longitude; 
    }

    useEffect(() => {
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            setMessage("Navegador não suporta Geolocalização");
        }
    }, []);

    return (
        <Crud 
            title="Visitas"
            endPoint="visita_vendedores"
            searchFieldName="search"
            emptyObject={{
                id: '',
                cliente_id: '',
                data_visita: `${(new Date()).getFullYear()}-${(new Date()).getMonth() + 1}-${(new Date()).getDate()}`,
                hora_visita: `${("00" + (new Date()).getHours()).slice(-2)}:${("00" + (new Date()).getMinutes()).slice(-2)}`,
                lat_check_in: '',
                lng_check_in: '',
                lat_check_out: '',
                lng_check_out: '',
                lat: position.lat,
                lng: position.lng,
                observacao: ''
            }}
            // enableBtnDelete={false}
            // enableBtnSave={false}
            // enableBtnNew={false}
            
            fields={[
                { name: 'id', label: 'Dados', classBody: 'min-width' },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td>
                        <Typography>
                            <strong>Cód. Visita: #{item.id}</strong>
                        </Typography>
                        <Typography>
                            <strong>
                                {item.cliente.razao_social}
                                {item.nome_fantasia ? ` - ${item.nome_fantasia}` : ''}
                            </strong>
                        </Typography>
                        <Typography className="detail">
                            <strong>Data: </strong>
                            {item.data_visita_f}<br />
                        </Typography>
                        <Typography className="detail">
                            <strong>Hora: </strong>
                            {item.hora_visita} <br />
                        </Typography>
                    </td>
                    
                </>
            )}
            validation={(yup) => {
                return {
                    data_visita: yup.string().required("Campo obrigatório"),
                    hora_visita: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}


