import React, { useContext, useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import HelperContext from 'src/context/helper';
import Api from 'src/core/api';

export default function HistoryStatusOrder() {

    const [data, setData] = useState([]);
    const { viewHistoryPedido, setViewHistoryPedido } = useContext(HelperContext);

    async function getData() {
        if(viewHistoryPedido) {
            const response = await Api('get', `historicos`, '', {
                params: {
                    pedido_id: viewHistoryPedido
                }
            });        
            setData(response.data);
        }
        
    }

    useEffect(() => {
        getData();
    }, [viewHistoryPedido]);

    return (
        <>
            <Modal
                show={viewHistoryPedido ? true : false}
                onHide={() => setViewHistoryPedido('')}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Histórico de Pedido
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Data</th>
                                <th>Hora</th>
                                <th>Status</th>
                                <th>Observação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.data}</td>
                                    <td>{item.hora}</td>
                                    <td>{item.status_f}</td>
                                    <td>{item.observacao}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}
