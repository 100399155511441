import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { Button } from '@mui/material';

export default function InputSearch(props) {
    return (
        <form className="form-inline mr-auto w-100 navbar-search">
            <div className="input-group">
                <input 
                    type="text" 
                    value={props.value}
                    onChange={e => props.handleText(e.target.value)}
                    placeholder="Pesquisar" 
                />
                {/* <Button style={{ float: 'right' }} variant="contained" onClick={() => props.loadData()} type="button">
                    <BsSearch color="#FFF" />
                </Button> */}
            </div>
        </form>
    );
}