import MainRouter from "./routes/MainRouter";
import LoadingScreen from 'src/components/LoadingScreen';
import './index.css';
function App() {
  return (
    <>
      {/* <LoadingScreen /> */}
      <MainRouter />
    </>
  );
}

export default App;
