import React, { useEffect } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';
import { Col, Row } from 'react-bootstrap';
import Util from 'src/core/Util';
import Message from 'src/core/Message';
import { Button, Grid } from '@mui/material';

export default function Login() {

    let deferredPrompt; // Allows to show the install prompt
    let setupButton;

    async function handleSubmit(values, actions) {
        const response = await Api('post', 'login-app', '', values);
        console.log(response);
        if(response) {
            Util.setToken(response.token);
            Message.success(response.message);
            setTimeout(() => {
                Util.redirect();
            }, 1000);
        }
        actions.setSubmitting(false);
        
    }

    const validation = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
        password: Yup.string().required('Campo obrigatório')
    });

    function installApp() {
        // Show the prompt
        deferredPrompt.prompt();
        setupButton.disabled = true;
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA setup accepted');
                    // hide our user interface that shows our A2HS button
                    setupButton.style.display = 'none';
                } else {
                    console.log('PWA setup rejected');
                }
                deferredPrompt = null;
            });
    }

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            alert("DSADSADA");
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            console.log("beforeinstallprompt fired");
            if (setupButton == undefined) {
                setupButton = document.getElementById("setup_button");
            }
            // Show the setup button
            setupButton.style.display = "block";
            setupButton.disabled = false;
        });

        window.addEventListener('appinstalled', (evt) => {
            console.log("appinstalled fired", evt);
        });

    }, []);

    return (
        <div id="login" className="">
            <div className="row">
                <div className="col s10 offset-s1 m12">
                    <div className="login_wrapper">
                        <div className="animate form login_form">
                        <section className="login_content">
                            <Formik
                                enableReinitialize
                                onSubmit={handleSubmit}
                                initialValues={{
                                    email: '',
                                    password: ''
                                }}
                                validationSchema={validation}
                            >
                                {(objects) => (
                                    <form onSubmit={objects.handleSubmit}>
                                        <img src="http://parlumin.com.br/img/core-img/logo.png" />
                                        <Field 
                                            label="E-mail"
                                            name="email"
                                            id="email"
                                            type="email"
                                            required
                                            component={InputText}
                                            error={<ErrorMessage name="email" />}
                                        />
                                        <Field 
                                            label="Senha"
                                            name="password"
                                            id="password"
                                            type="password"
                                            required
                                            component={InputText}
                                            error={<ErrorMessage name="password" />}
                                        />
                                        <br />
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Button 
                                                    style={{ display: 'block', margin: '0 auto' }} 
                                                    variant="contained" 
                                                    className="blue" 
                                                    size="sm" 
                                                    type="submit">
                                                    Acessar
                                                </Button>
                                            </Grid>
                                            {/* <Col md={12}>
                                                <Button 
                                                    id="setup_button" 
                                                    onClick={() => installApp()} 
                                                    style={{ width: '100%' }} 
                                                    variant="contained" 
                                                    className="blue" 
                                                    name="Instalar App" 
                                                    size="sm" 
                                                    type="button" 
                                                >Instalar App</Button>
                                            </Col> */}
                                        </Grid>
                                        <br />
                                    </form>
                                )}
                            </Formik>
                        </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}