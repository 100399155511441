import React, { useContext, useEffect, useState } from 'react';
import { Table, Modal } from 'react-bootstrap';
import Api from 'src/core/api';
import Pagination from 'src/components/Pagination';
import Helpers from 'src/core/Helpers';
import ReportPdf from 'src/components/ReportPdf';
import Util from 'src/core/Util';
import AuthContext from 'src/context/auth';
import { Button, Grid } from '@mui/material';
import FilterGlobal2 from 'src/components/FilterGlobal2';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';



export default function Vendas() {

    const { user } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
        
    const [pagination, setPagination] = useState({});  
    const [show, setShow] = useState(false);

    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({
        has_tabela: true
    });
    
  
    async function getPedidos() {
        const response = await Api('get', 'pedidos', '', {
            params: {
                page: page,
                vendedor_id: user.colaborador_id,
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    useEffect(() => {
        getPedidos();
    }, [page, params]);


    return (
        <div className="" role="main" style={{ padding: '10px' }}>
            <ReportPdf 
                title="Vendas"
                endPoint="vendas"
                params={{
                    pedidos: selected,
                    vendedor_id: user.colaborador_id,
                    ...params,
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            
            <Grid container>
                <h5>
                    <Link to="relatorios">
                        <Button>
                            <BiArrowBack size="30px" />
                        </Button>
                    </Link>
                    Vendas
                </h5>
                <Grid item xs={12}>
                    <Button 
                        style={{ float: 'right' }} 
                        size="small"
                        variant="contained" 
                        onClick={() => setShowPdf(true)}>Gerar PDF</Button>
                </Grid>
            </Grid>
            <FilterGlobal2
                params={params} 
                setParams={setParams} 
                setSelected={setSelected} 
                list={pedidos} 
                isEnableFieldSearch={true}
                isEnableFieldStatus={true}
                isEnableFieldData={true}
                isEnableFieldCodigo={false}
                isEnableFieldAllPedidos={true}
                isEnableFieldAscDesc={true}

            />
            
            
            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                    <tr>
                        <th>
                            Dados
                        </th>
                    </tr>
                    {pedidos.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td style={{ width: '25px' }}>
                                Código: {item.codigo} <br />
                                Status: {Util.getStatusSpan(item.status)}<br />
                                <strong>Razão Social: </strong> {item.cliente.razao_social} <br />
                                <strong>CNPJ/CPF: </strong> {item.cliente.cnpj ? item.cliente.cnpj : item.cliente.cpf} <br />
                                <strong>Bairro: </strong> {item.cliente.bairro} - <strong>Cidade: </strong> {item.cliente.cidade} - <strong>Estado: </strong> {item.cliente.uf} 
                                <br />
                                <strong>Vendedor: </strong> {item.vendedor.name} <br />
                                Data: {item.data} <br />
                                <br /><br />
                                Valor: {Helpers.converterFloatReal(item.total)}
                            </td>
                            
                            
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Pedidos Agrupados
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tr>
                            <th>Descrição</th>
                            <th  style={{ textAlign: 'right' }}>Quantidade</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                        {produtos.map((item, index) => (
                            <tr>
                                <td>{item.produto.titulo}</td>
                                <td style={{ textAlign: 'right' }}>{item.quantidade}</td>
                                <td style={{ textAlign: 'right' }}>{Helpers.converterFloatReal(item.valor)}</td>
                            </tr>
                        ))}
                        <tr>
                            <th>Total</th>
                            
                            <th colSpan="2" style={{ textAlign: 'right' }}>{Helpers.converterFloatReal(produtos.length > 0 && produtos[0].total)}</th>
                        </tr>
                    </Table>
                </Modal.Body>
            </Modal>
            
           
            <hr />
        </div>
    );
}
