import React from 'react';
import Menu from './Menu';

export default function Body({ children }) {
    return (
        <div>
            <Menu />
            <div className="">
                {children}
            </div>
        </div>
    );
}
