import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Api from 'src/core/api';
import Pagination from 'src/components/Pagination';

import ReportPdf from 'src/components/ReportPdf';

import { Box } from '@mui/system';
import FilterGlobal2 from 'src/components/FilterGlobal2';
import AuthContext from 'src/context/auth';
import { Button, Grid } from '@mui/material';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export default function UltimasVendas() {

    const { user } = useContext(AuthContext);
    const [page, setPage] = useState(0);
    const [clientes, setClientes] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({});
    
    async function getCLientes() {
        const response = await Api('get', 'ultimas-vendas', '', {
            params: {
                page: page,
                vendedor_id: user.colaborador_id,
                ...params
            }
        });        
        setClientes(response.list);
        setPagination(response.data);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    useEffect(() => {
        getCLientes();
    }, [page, params]);


    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <div className="" role="main">
                <ReportPdf 
                    title="Últimas Vendas"
                    endPoint="ultimasVendas"
                    params={{
                        pedidos: selected,
                        vendedor_id: user.colaborador_id,
                        ...params,
                    }}
                    show={showPdf}
                    setShow={setShowPdf}
                />
                
                
                <Grid container>
                    <h5>
                        <Link to="relatorios">
                            <Button>
                                <BiArrowBack size="30px" />
                            </Button>
                        </Link>
                        Últimas Vendas
                    </h5>    
                    <Grid item xs={12}>
                        <Button 
                            style={{ float: 'right' }} 
                            size="small"
                            variant="contained" 
                            onClick={() => setShowPdf(true)}>Gerar PDF</Button>
                    </Grid>
                </Grid>
                <FilterGlobal2
                    params={params} 
                    setParams={setParams} 
                    setSelected={setSelected} 
                    list={clientes} 
                    isEnableFieldSearch={true}
                    isEnableFieldStatus={false}
                    isEnableFieldData={false}
                    isEnableFieldCodigo={false}
                    isEnableFieldAllPedidos={false}
                    isEnableFieldAscDesc={true}

                />
                {!clientes.length && 'Nenhum resultado'}
                <Table className={!clientes.length && 'none'}>
                        <tr>
                            <th>Dados</th>
                            
                        </tr>
                        {clientes.map((item, index) => (
                            <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                                <td>
                                    <strong>Razão Social: </strong> {item.razao_social} <br />
                                    <strong>CNPJ/CPF: </strong> {item.cnpj ? item.cnpj : item.cpf} <br />
                                    <strong>Bairro: </strong> {item.bairro} - <strong>Cidade: </strong> {item.cidade} - <strong>Estado: </strong> {item.uf} 
                                    <br />
                                    <strong>Vendedor: </strong> {item.vendedor.name} <br /><br />
                                    Data do Último Pedido / Dias:  {item.visita ? item.visita.data : ''} - {item.visita ? item.visita.dias + ' Dias' : ''}
                                    <br />
                                    Data da Última Visita / Dias: {item.venda ? item.venda.data : ''} - {item.venda ? item.venda.dias + ' Dias' : ''}
                                </td>
                            </tr>
                        ))}
                </Table>
                <Pagination
                    changePage={data => setPage(data.page)} 
                    data={pagination}                            
                />
                <hr />
            </div>
        </Box>
    );
}
