import { Card, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Relatorios() {
    return (
        <Container sx={{ marginTop: '20px' }}>
            <Grid>
                <Card style={{ padding: '10px 20px', margin: '5px 0px' }}>
                    <Link to="relatorios-vendas">
                        Relatório de Vendas
                    </Link>
                </Card>
                <Card style={{ padding: '10px 20px', margin: '5px 0px' }}>
                    <Link to="relatorios-ultimas-vendas">
                        Relatório de Últimas Vendas
                    </Link>
                </Card>
                <Card style={{ padding: '10px 20px', margin: '5px 0px' }}>
                    <Link to="relatorios-clientes">
                        Relatório de Clientes
                    </Link>
                </Card>
                <Card style={{ padding: '10px 20px', margin: '5px 0px' }}>
                    <Link to="relatorios-produtos">
                        Relatório de Produtos
                    </Link>
                </Card>
            </Grid>
        </Container>
    );
}