import React, { useContext } from 'react';
import AuthContext from 'src/context/auth';


export default function Help() {

    const { user } = useContext(AuthContext);

    return (
        <div id="crud">
            <div id="content-title">
                <h5>
                    Ajuda
                </h5>
            </div>
            <div id="content">
                
            </div>
        </div>
    );
}