
import React, { useEffect, useState } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import Crud from 'src/components/Crud';
import { InputText } from 'src/components/FormHelper/Input';
import Pagination from 'src/components/Pagination';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import ReportPdf from 'src/components/ReportPdf';

const FormWrapper = ({ Field, ErrorMessage, values }) => {

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [pagination, setPagination] = useState({});
    const [showPdf, setShowPdf] = useState(false);
    
    async function getProdutos() {
        if(values.id) {
            const response = await Api('get', `produtos/list-produtos-by-porcentagem/${values.id}`, '', {
                params: {
                    page: page,
                }
            });        
            setList(response.data);
            setPagination(response);
        }
    }

    useEffect(() => {
        getProdutos();
    }, [page]);

    return (
        <>
            <ReportPdf 
                title="Tabela de Preços"
                endPoint="tabelaProduto"
                params={{
                    tabela_id: values.id
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            {values.id &&
                <Grid>
                    <Grid>
                        <Button
                            style={{ float: 'right' }} 
                            size="small" 
                            variant="contained" 
                            onClick={() => setShowPdf(true)}>
                                Gerar PDF
                        </Button>
                    </Grid>
                </Grid>
            }
            <Grid container>
                <Grid item xs={12}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field 
                        required
                        disabled
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field 
                        required
                        disabled
                        id="porcentagem"
                        label="Porcentagem"
                        name="porcentagem"
                        component={InputText}
                        error={<ErrorMessage name="porcentagem" />}
                    />
                </Grid>
            </Grid> 
            
            <Grid container>
                <Grid item xs={12}>
                    <h6>Composição dos Items Abaixo</h6>
                    {list.map((item, index) => (
                        <Card key={index} style={{ marginBottom: '20px' }}>
                            <strong>Título: </strong>
                            <br />
                            {item.titulo} <br />
                            Tipo Unidade: {item.tipo_unidade}<br />
                            Quantidade: {item.quantidade}<br />
                            Preço: {Helpers.converterFloatReal(item.valor)}<br />
                            Preço + Porcentagem: {Helpers.converterFloatReal(item.preco_total)}<br />
                        </Card>
                    ))}
                </Grid>
                <Pagination
                    changePage={data => setPage(data.page)} 
                    data={pagination}                            
                />
            </Grid>           
        </>
    );
}

export default function TablePrices() {
    return (
        <Crud 
            title="Tabela de Preços"
            endPoint="tabela-precos"
            emptyObject={{
                name: '',
                category_id: '',
            }}
            enableBtnDelete={false}
            enableBtnSave={false}
            enableBtnNew={false}
            fields={[
                { name: 'name', label: 'Nome' }
            ]}
            fieldsHtml={({item}) => (
                <>
                    
                    <td>
                        <Typography>
                            <strong>
                                {item.name}
                            </strong>
                        </Typography>
                        
                    </td>
                    
                </>
            )}
            validation={(yup) => {
                return {
                    name: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}

