import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'src/components/Pagination';
import Api from 'src/core/api';
import ReportPdf from 'src/components/ReportPdf';
import Message from 'src/core/Message';
import AuthContext from 'src/context/auth';
import { Button, Grid } from '@mui/material';
import FilterGlobal2 from 'src/components/FilterGlobal2';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

export default function Clientes() {

    const { user } = useContext(AuthContext);
    const [dataIni, setDataIni] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [page, setPage] = useState(0);
    const [clientes, setClientes] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({
        has_tabela: true,
        all_clientes: false,
    });
  
    async function getClientes() {
        const response = await Api('get', 'clients', '', {
            params: {
                page: page,
                vendedor_id: user.colaborador_id,
                ...params
            }
        });        
        setClientes(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    async function agrupar() {
        if(!selected.length) {
            Message.infor("Selecione pelo menos um pedido.");
            return false;
        }
        setShowPdf(true);
    }

    useEffect(() => {
        getClientes();
    }, [page, params]);

    useEffect(() => {
        if(!dataIni && !dataFim) {
            // getTotais();
        }
    }, [dataIni, dataFim]);

    return (
        <div className="" role="main" style={{ padding: '10px' }}>
            <ReportPdf 
                title="Clientes"
                endPoint="clientes"
                params={{
                    clientes: selected,
                    vendedor_id: user.colaborador_id,
                    ativo: 1,
                    ...params
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            
            
            <Grid container>
                <h5>
                    <Link to="relatorios">
                        <Button>
                            <BiArrowBack size="30px" />
                        </Button>
                    </Link>
                    Clientes
                </h5>
                <Grid item xs={12}>
                    <Button 
                        style={{ float: 'right' }} 
                        size="small"
                        variant="contained" 
                        onClick={() => agrupar(true)}>Gerar PDF</Button>
                </Grid>
            </Grid>
            <FilterGlobal2
                params={params} 
                setParams={setParams} 
                setSelected={setSelected} 
                list={clientes} 
                isEnableFieldSituacao={true}
                isEnableFieldSearch={true}
                isEnableFieldStatus={true}
                isEnableFieldData={true}
                isEnableFieldCodigo={false}
                isEnableFieldAllPedidos={true}
                isEnableFieldAscDesc={true}

            />
            
            
            
            {!clientes.length && 'Nenhum resultado'}
            <Table className={!clientes.length && 'none'}>
                    <tr>
                        <th>Dados</th>
                    </tr>
                    {clientes.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td>
                                <strong>Razão Social: </strong> {item.razao_social} <br />
                                <strong>Bairro: </strong> {item.bairro} - <strong>Cidade: </strong> {item.cidade} - <strong>Estado: </strong> {item.uf} <br />
                                <strong>Vendedor: </strong>{item.vendedor.name}<br />
                                CNPJ/CPF: {item.cnpj ? item.cnpj : item.cpf}<br />
                                Possui Tabela de Preço: {item.tabela_id ? 'Sim' : 'Não'}
                            </td>
                        </tr>
                    ))}
            </Table>
            <hr />
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
        </div>
    );
}
