import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-materialize';
import axios from 'axios';
import Crud from 'src/components/Crud';
import { InputMask, InputText, InputTextarea, Select, InputRadioCheckbox } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import AuthContext from 'src/context/auth';
import { Typography } from '@mui/material';

const FormWrapper = ({ Field, ErrorMessage, errors, values, setFieldValue  }) => {
    const [colaborador, setColaborador] = useState([]);
    const [tabela, setTabela] = useState([]);
    
    async function getColaborador() {
        const response = await Api('get', 'colaborador/options', '', {});        
        setColaborador(response.data);
    }

    async function getTabelaPreco() {
        const response = await Api('get', 'tabela-precos/options', '', {});        
        setTabela(response.data);
    }

    async function consultCNPJ(){

        let cnpj = values.cnpj.replace('.', '');
        cnpj = cnpj.replace('.', '');
        cnpj = cnpj.replace('.', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.replace('-', '');
        axios.get(`https://publica.cnpj.ws/cnpj/${cnpj}`)
        .then(function (response) {
            console.log(response);
            if(response.status !== 200) {
                
                Message.error(response.data.message);
            } else {
                setFieldValue('razao_social', response.data.razao_social);
                setFieldValue('nome_fantasia', response.data.estabelecimento.nome_fantasia);
                setFieldValue('email', response.data.estabelecimento.email);
                setFieldValue('fone', response.data.estabelecimento.ddd1 + response.data.estabelecimento.telefone1);
                setFieldValue('logradouro', response.data.estabelecimento.logradouro);
                setFieldValue('numero', response.data.estabelecimento.numero);
                setFieldValue('bairro', response.data.estabelecimento.bairro);
                setFieldValue('cidade', response.data.estabelecimento.cidade.nome);
                setFieldValue('complemento', response.data.estabelecimento.complemento);
                setFieldValue('uf', response.data.estabelecimento.estado.nome);
                setFieldValue('cep', response.data.estabelecimento.cep);
            }
        })
        .catch(function (error) {
            
            // console.log(error.response.data.detalhes);
            console.log(error.response.data.detalhes);
            Message.error(error.response.data.detalhes);
            // Message.error("Servidor da Receita Federal possui muitas requisições, por favor, aguarde um minuto. Máximo de 3 requisições por minuto.");
        });
        // axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
        // .then(function (response) {
        //     if(response.data.status === 'ERROR') {
        //         Message.error(response.data.message);
        //     } else {
        //         setFieldValue('razao_social', response.data.nome);
        //         setFieldValue('nome_fantasia', response.data.fantasia);
        //         setFieldValue('fone', response.data.telefone);
        //         setFieldValue('logradouro', response.data.logradouro);
        //         setFieldValue('numero', response.data.numero);
        //         setFieldValue('bairro', response.data.bairro);
        //         setFieldValue('cidade', response.data.municipio);
        //         setFieldValue('complemento', response.data.complemento);
        //         setFieldValue('uf', response.data.uf);
        //         setFieldValue('cep', response.data.cep);
        //     }
        // })
        // .catch(function (error) {
        //     Message.error("Servidor da Receita Federal possui muitas requisições, por favor, aguarde um momento.");
        // });
    }

    // async function consultCNPJ(){

    //     let cnpj = values.cnpj.replace('.', '');
    //     cnpj = cnpj.replace('.', '');
    //     cnpj = cnpj.replace('.', '');
    //     cnpj = cnpj.replace('/', '');
    //     cnpj = cnpj.replace('-', '');
    //     axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
    //     .then(function (response) {
    //         if(response.data.status === 'ERROR') {
    //             Message.error(response.data.message);
    //         } else {
    //             setFieldValue('razao_social', response.data.nome);
    //             setFieldValue('nome_fantasia', response.data.fantasia);
    //             setFieldValue('fone', response.data.telefone);
    //             setFieldValue('logradouro', response.data.logradouro);
    //             setFieldValue('numero', response.data.numero);
    //             setFieldValue('bairro', response.data.bairro);
    //             setFieldValue('cidade', response.data.municipio);
    //             setFieldValue('complemento', response.data.complemento);
    //             setFieldValue('uf', response.data.uf);
    //             setFieldValue('cep', response.data.cep);
    //         }
    //     })
    //     .catch(function (error) {
    //         Message.error("Servidor da Receita Federal possui muitas requisições, por favor, aguarde um momento.");
    //     });
    // }

    useEffect(() => {
        getColaborador();
        getTabelaPreco();
    }, []);

    return (
        <>
            <Row>
                {/* <Col s={12}>
                    <Field 
                        disabled
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        error={<ErrorMessage name="id" />}
                    />
                </Col> */}
                <Col s={12}>
                    <Field 
                        required
                        id="razao_social"
                        label="Razão Social"
                        name="razao_social"
                        component={InputText}
                        error={<ErrorMessage name="razao_social" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        required
                        id="nome_fantasia"
                        label="Nome Fantasia"
                        name="nome_fantasia"
                        component={InputText}
                        error={<ErrorMessage name="nome_fantasia" />}
                    />
                </Col>
            </Row>

            <Row>
                <Col s={12}>
                    <Field 
                        id="cnpj"
                        label="Cnpj"
                        name="cnpj"
                        mask="##.###.###/####-##"
                        component={InputMask}
                        error={<ErrorMessage name="cnpj" />}
                    />
                </Col>
                <Col s={12}>
                    <Button type="button" variant="info" onClick={() => consultCNPJ()} style={{ marginTop: '27px' }}>Buscar CNPJ</Button>
                </Col>
                <Col s={12}>
                    <Field 
                        
                        id="cpf"
                        label="Cpf"
                        name="cpf"
                        mask="###.###.###-##"
                        component={InputMask}
                        error={<ErrorMessage name="cpf" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        required
                        id="email"
                        label="Email"
                        name="email"
                        component={InputText}
                        error={<ErrorMessage name="email" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Field 
                        required
                        id="fone"
                        label="Fone"
                        name="fone"
                        mask="(##) ####.####"
                        component={InputMask}
                        error={<ErrorMessage name="fone" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        id="celular"
                        label="Celular"
                        name="celular"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="celular" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        id="telefone"
                        label="Telefone"
                        name="telefone"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="telefone" />}
                    />
                </Col>
            </Row>

            <Row>
                <Col s={12}>
                    <Field 
                        required
                        id="logradouro"
                        label="Endereço"
                        name="logradouro"
                        component={InputText}
                        error={<ErrorMessage name="logradouro" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        required
                        id="numero"
                        label="Número"
                        name="numero"
                        component={InputText}
                        error={<ErrorMessage name="numero" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        id="complemento"
                        label="Complemento"
                        name="complemento"
                        component={InputText}
                        error={<ErrorMessage name="complemento" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        required
                        id="bairro"
                        label="Bairro"
                        name="bairro"
                        component={InputText}
                        error={<ErrorMessage name="bairro" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Field 
                        required
                        id="cidade"
                        label="Cidade"
                        name="cidade"
                        component={InputText}
                        error={<ErrorMessage name="cidade" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        required
                        id="uf"
                        label="UF"
                        name="uf"
                        component={InputText}
                        error={<ErrorMessage name="uf" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        required
                        id="cep"
                        label="CEP"
                        name="cep"
                        component={InputText}
                        error={<ErrorMessage name="cep" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Field 
                        required
                        id="tabela_id"
                        label="Tabela de Preços"
                        name="tabela_id"
                        placeholder="Selecione"
                        component={Select}
                        list={tabela}
                        error={<ErrorMessage name="tabela_id" />}
                    />
                </Col>
            </Row>
            {/* {JSON.stringify(values, null, 2)} */}
            <Row>
                <Col s={12}>
                    <Field 
                        id="nome_contato"
                        label="Nome do Contato"
                        name="nome_contato"
                        component={InputText}
                        error={<ErrorMessage name="nome_contato" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        id="email_contato"
                        label="E-mail do Contato"
                        name="email_contato"
                        component={InputText}
                        error={<ErrorMessage name="email_contato" />}
                    />
                </Col>
                <Col s={12}>
                    <Field 
                        id="telefone_contato"
                        label="Telefone do Contato"
                        name="telefone_contato"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="telefone_contato" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Field 
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        placeholder="Selecione"
                        component={InputTextarea}
                        error={<ErrorMessage name="observacao" />}
                    />
                </Col>
            </Row>
        </>
    );
}

export default function Clients() {

    const { user } = useContext(AuthContext);

    return (
        <Crud 
            title="Clientes"
            endPoint="clients-vendedor"
            enableBtnEdit={false}
            enableBtnDelete={false}
            emptyObject={{
                razao_social: '',
                nome_fantasia: '',
                cnpj:  '',
                fone:  '',
                celular: '',
                logradouro:  '',
                numero:  '',
                bairro: '',
                cidade: '',
                uf:  '',
                cep:  '',
                situacao: false,
                vendedor_id: user && user.id
            }}
            
            fields={[
                { name: 'id', label: 'Dados', classBody: 'min-width' },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td>
                        <Typography>
                            <strong>
                                {item.razao_social}
                                {item.nome_fantasia ? ` - ${item.nome_fantasia}` : ''}
                            </strong>
                        </Typography>
                        <Typography className="detail">
                            <strong>
                                Situação: {item.situacao ? 'Ativo' : 'Inativo'}
                            </strong>
                        </Typography>
                    </td>
                </>
            )}
            validation={(yup) => {
                return {
                    razao_social: yup.string().required("Campo obrigatório"),
                    nome_fantasia: yup.string().required("Campo obrigatório"),
                    fone: yup.string().required("Campo obrigatório"),
                    logradouro: yup.string().required("Campo obrigatório"),
                    numero: yup.string().required("Campo obrigatório"),
                    bairro: yup.string().required("Campo obrigatório"),
                    cidade: yup.string().required("Campo obrigatório"),
                    uf: yup.string().required("Campo obrigatório"),
                    cep: yup.string().required("Campo obrigatório"),
                    
                    tabela_id: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
