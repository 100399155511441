import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Api from '../../../core/api';

export const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

function SelectAutoComplete(props) {
  const [list, setList] = useState([]);
  const [groupedOptions, setGroupedOptions] = useState([
    {
      label: props.label,
      options: list,
    }
  ]);

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );  
 
  function handleChange(option) {
    if(props.handleChange) {
      props.handleChange(option);
    } else {
      props.form.setFieldValue(props.field.name, option.value);
    }
    
  }

  async function getList() {
    const response = await Api('get', props.endPoint, '', {
      params: {
        ...props.params
      }
    });        
    setGroupedOptions([
      {
        label: props.label,
        options: response.data,
      }
    ]);
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <label>{props.label}</label>
      <Select
        defaultValue={{}}
        onChange={handleChange}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
  
}

SelectAutoComplete.defaultProps = {
  params: {}
};

export default SelectAutoComplete;