import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { Button, Card, Grid as GridM, Menu, MenuItem} from '@mui/material';


export function Btns(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            {(props.enableBtnEdit || props.enableBtnDelete) &&
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{ float: 'right' }}
                >
                    <BiDotsVerticalRounded size="2.3em" color="black" />
                </Button>
            }
            <Menu
                key={props.idx}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}>
                {props.enableBtnEdit &&                                         
                    <MenuItem 
                        onClick={() => {
                                handleClose();
                                if(props.handleCustomEdit) {
                                    props.handleCustomEdit(props.item)
                                } else {
                                    props.handleEdit(props.item)
                                }
                            }}>
                        Editar
                    </MenuItem>
                }
                {props.enableBtnDelete &&                                         
                    <MenuItem 
                        onClick={() => {
                            handleClose();
                            props.handleDelete(props.item);
                        }}>
                        Excluir
                    </MenuItem>
                }
            </Menu>
        </>
    );
}

export default function Grid(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [show, setShow] = useState(false);
    const FieldsHtml = props.fieldsHtml;
    console.log(props);
    return (
        <Table>
            <thead>
                <tr>
                    {props.fields.map((item, index) => (
                        <th key={index} >{item.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.list && props.list.map((item, idx) => (
                    <Card key={idx} style={{ marginBottom: '20px' }}>
                        {(props.enableBtnEdit || props.enableBtnDelete) && 
                            <Btns item={item} idx={idx} {...props}  />
                        }
                        {props.fieldsHtml && <FieldsHtml item={item} {...props} />}
                        {!props.fieldsHtml && 
                            <>
                                {props.fields.map(i => (
                                    <td className={i.classBody || ''}>{item[i.name]}</td>
                                ))}
                            </>
                        }
                        
                        {/* {(props.enableBtnEdit || props.enableBtnDelete) &&
                        <>
                            <GridM container spacing>
                                <GridM item xs={8}></GridM>
                                <GridM item>
                                    {props.enableBtnEdit && 
                                        <a href onClick={() => {
                                            if(props.handleCustomEdit) {
                                                props.handleCustomEdit(item)
                                            } else {
                                                props.handleEdit(item)
                                            }
                                        }}>
                                            {props.titleBtnEdit ? props.titleBtnEdit : 'Editar'}
                                        </a>
                                    }
                                </GridM>
                                <GridM item>
                                    {props.enableBtnDelete && 
                                        <a href onClick={() => props.handleDelete(item)}>
                                            Excluir
                                        </a>
                                    }
                                </GridM>
                            </GridM>
                            </>
                        } */}
                        
                    </Card>
                ))}
            </tbody>
        </Table>
    );
}

Grid.defaultProps = {
    list: [],
    fields: [],
    fieldsHtml: null,
    handleEdit: null,
    handleDelete: null,
    enableBtnEdit: true,
    enableBtnDelete: true,
    handleCustomEdit: null,
    titleBtnEdit: '',
};

