import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText, Select } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import Helpers from 'src/core/Helpers';
import { Button, Table, TableHead, TableBody, Grid, Typography, Card } from '@mui/material';
import FinishOrder from './FinishOrder';
import AuthContext from 'src/context/auth';
import Util from 'src/core/Util';
import useDebounce from 'src/components/useDebounce';

const FormWrapper = ({ view, values, Field, ErrorMessage, setFieldValue, handleEdit }) => {

    const { user } = useContext(AuthContext);
    // const [listCategories, setListCategories] = useState([]);
    const [produto, setProduto] = useState(null);
    const [listItens, setListItens] = useState([]);
    const [showFinish, setShowFinish] = useState(false);
    const [showQuantidade, setShowQuantidade] = useState(false);
    const [dataQuantidade, setDataQuantidade] = useState(null);

    const [clientes, setClientes] = useState([]);
    const [params, setParams] = useState({});
    const debouncedSearchTerm = useDebounce(params, 500);
    async function getClientes() {
        const response = await Api('get', 'clients', '', {
            params: {
                vendedor_id: user.colaborador_id,
                ativo: 1,
                ...params
            }
        });        
        setClientes(response.data);
    }

    async function getCategories() {
        // const response = await Api('get', 'categories/options', '', {});        
        // setListCategories(response.data);
    }

    async function addClient(cliente) {
        setFieldValue('cliente_id', cliente.value);
    }

    async function updateQuantidade() {
        if(dataQuantidade && dataQuantidade.id && dataQuantidade.quantidade) {
            await Api('put', 'itens-pedidos/'+dataQuantidade.id, '', {
                quantidade: dataQuantidade.quantidade
            });  
            handleEdit({ id: values.id });
            getItens();
            setDataQuantidade(null);
            setShowQuantidade(false);
        } else {
            Message.infor("Por favor, informe a quantidade.");
        }
        
    }

    async function getItens() {
        const response = await Api('get', 'itens-pedidos', '', {
            params: {
                pedido_id: values.id
            }
        });        
        setListItens(response.data);
    }
    
    async function addProduto() {
        if(values.status !== 'open' && values.status !== 'pending') {
            Message.infor("Não foi possível adicionar um produto, pois o pedido não se encontra aberto.")
            return false;
        }
        if(!produto) {
            Message.infor("Por favor, selecione o produto");
            return null;
        }

        if(!values.quantidade) {
            Message.infor("Por favor, informe a quantidade");
            return null;
        }

        await Api('post', 'itens-pedidos', '', {
            pedido_id: values.id,
            produto_id: produto,
            quantidade: values.quantidade,
        });        
        // setListProduto([...listProduto, { id: produto, quantidade: values.quantidade }]);
        setFieldValue('quantidade', 0);
        setFieldValue('produto_id', '');
        handleEdit({ id: values.id });
        setProduto(null);
        getItens();

    }

    async function removerItem(item_id) {
        if(values.status !== 'open') {
            Message.infor("Não foi possível remover o produto, pois o pedido não se encontra aberto.")
            return false;
        }
        const check = await Message.confirmation("Deseje remover este item?");
        if(check) {
            await Api('delete', `itens-pedidos/${item_id}`, '', {});        
            setFieldValue('quantidade', 0);
            setProduto(null);
            getItens();
            Message.success("Item removido com Sucesso");
        }
    }
    
    async function handleProduto(produto) {
        setProduto(produto.value);
    }  

    useEffect(() => {
        if(view === 'new') {
            getClientes();
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        getCategories();
        if(view === 'edit') {
            getItens();
        }
    }, []);

    return (
        <>
            <div>
                {view === 'new' ? 
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Field
                                label="Tipo"
                                name="tipo"
                                id="tipo"
                                component={Select}
                                list={[
                                    { label: 'Pedido', value: 'P' },
                                    { label: 'Orçamento', value: 'O' }
                                ]}
                            /> 
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className='form-group'>
                                <label>Buscar por Razão Social, CNPJ, Endereço, Cidade, Bairro, Estado</label>
                                <input
                                    type="text"
                                    name="search"
                                    className="form-control"
                                    onChange={e => setParams({ search: e.target.value })}
                                    placeholder="Pesquisa"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {clientes.map((item, index) => (
                                <Card 
                                    style={{ 
                                        margin: '10px 0px',
                                        background: values.cliente_id === item.id ? '#CCC' : '',
                                        borderBottom: '1px solid #CCC',
                                        minHeight: '130px'
                                    }}
                                    key={index} 
                                    onClick={() => setFieldValue('cliente_id', item.id)}
                                >
                                    <Typography>
                                        <strong>{item.razao_social}</strong>
                                    </Typography>
                                    <Typography>
                                        CNPJ: {item.cnpj}
                                    </Typography>
                                    <Typography>
                                        {item.logradouro}, {item.numero} <br />
                                        {item.bairro} - {item.cidade}
                                    </Typography>
                                    <br />
                                </Card>
                            ))}
                            
                        </Grid>
                    </Grid>
                : 
                    <Grid container>
                        <Typography>
                            <strong>Código do Pedido</strong>: {values.codigo} <br />
                        </Typography>
                        <Typography>
                            <strong>Cliente</strong>:<br /> {values.cliente.razao_social || ''} <br />
                        </Typography>
                        <Typography>
                            <strong>Fantasia</strong>: {values.cliente.nome_fantasia} <br />
                            <strong>CNPJ</strong>: {values.cliente.cnpj} <br />
                            <strong>E-mail</strong>: {values.cliente.email} <br />
                        </Typography>
                    </Grid>
                }
            </div>
            
            {view === 'edit' && 
                <>
                    <Row>
                        <h5 className="center">Adicionar Produtos</h5>
                    </Row>
                    <Row>
                        <Col s={12} md={6} l={6}>
                            <Field
                                label="Selecione o Produto"
                                name="produto_id"
                                id="produto_id"
                                endPoint="produtos/options"
                                params={{
                                    tipo: 'produto'
                                }}
                                handleChange={handleProduto}
                                component={SelectAutoComplete}
                            />
                        </Col>
                        <Col s={12} md={6}>
                            <Field 
                                id="quantidade"
                                label="Quantidade"
                                name="quantidade"
                                type="number"
                                component={InputText}
                                error={<ErrorMessage name="quantidade" />}
                            />
                        </Col>
                        <Col s={12} md={12}>
                            <Button
                                className="w-100"
                                type="button"
                                variant="contained"
                                color="success"
                                onClick={() => addProduto()}>
                                    Adicionar
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <TableHead>
                                <tr>
                                    <td>Dados</td>
                                    <td>Ações</td>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {listItens.map((item, key) => (
                                    <tr key={key}>
                                        <td>
                                            <strong>Cód.</strong> {item.id} <br />
                                            <strong>{item.produto.titulo}</strong> <br />
                                            Valor: {Helpers.converterFloatReal(item.preco)} <br />
                                            <strong>SubTotal:</strong> {Helpers.converterFloatReal(item.quantidade * item.preco)} <br />
                                            <strong>Quant.: </strong> {` `}
                                            <a href onClick={() => {
                                                setShowQuantidade(true);
                                                setDataQuantidade({ id: item.id, quantidade: item.quantidade });
                                            }}>
                                                {item.quantidade}
                                            </a>
                                        </td>
                                        <td>
                                            <Button type="button" onClick={() => removerItem(item.id)}>X</Button>
                                        </td>
                                    </tr>
                                ))}
                            </TableBody>
                            
                        </Table>
                    </Row>
                    <Grid>
                        <Grid item xs={12}>
                            <table hover striped>
                                <tbody>
                                    <tr>
                                        <td>Total</td>
                                        <td style={{  textAlign: 'right' }}>
                                            <strong>
                                                {Helpers.converterFloatReal(values.total)}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Desconto</td>
                                        <td style={{  textAlign: 'right' }}>
                                            <strong>
                                                {Helpers.converterFloatReal(parseFloat(values.total) * parseFloat(values.desconto) / 100)}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '16px' }}>
                                            <strong>
                                                Total com Desconto
                                            </strong>
                                        </td>
                                        <td style={{ fontSize: '16px', textAlign: 'right' }}>
                                            <strong>
                                                {Helpers.converterFloatReal(parseFloat(values.total) - (parseFloat(values.total) * parseFloat(values.desconto) / 100))}
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    {values.status === 'open' && 
                        <Row>
                            <Button 
                                variant="contained" 
                                color="info"
                                size="large"
                                className="w-100"
                                onClick={() => setShowFinish(true)}
                                >
                                    Finalizar Pedido
                            </Button>
                        </Row>
                    }
                     {values.status === 'pending' && 
                        <Row>
                            <Button 
                                variant="contained" 
                                color="info"
                                size="large"
                                className="w-100"
                                onClick={() => setShowFinish(true)}
                                >
                                    Alterar Forma de Pagamento
                            </Button>
                        </Row>
                    }
                    <FinishOrder 
                        showFinish={showFinish}
                        setShowFinish={setShowFinish} 
                        handleEdit={handleEdit}
                        pedido_id={values.id} 
                    />
                    <Modal
                        show={showQuantidade}
                        onHide={() => setShowQuantidade(false)}
                        dialogClassName=""
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Atualizar Quantidade
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label>Quantidade</label>
                                <input 
                                    type="number" 
                                    className="form-control"
                                    value={dataQuantidade ? dataQuantidade.quantidade : 0}
                                    onChange={e => setDataQuantidade({...dataQuantidade, ...{ quantidade: e.target.value }})}
                                />
                            </div> 
                            <Button style={{ float: 'right' }} onClick={() => updateQuantidade()} variant="success" size="sm">Lançar</Button>
                        </Modal.Body>
                    </Modal>
                    
                </>
            }
        </>
    );
}

export default function Orders() {

    const { user } = useContext(AuthContext);
    const [position, setPosition] = useState({
        lat: '',
        lng: '',
    });

    function showPosition(position){
        setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
    }

    useEffect(() => {
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Navegador não suporta Geolocalização");
        }
    }, []);

    return (
        <Crud 
            title="Pedidos"
            endPoint="pedidos_vendedores"
            searchFieldName="search"
            paramsSearch={{
                vendedor_id: user.colaborador_id,
            }}
            // enableBtnEdit={false}
            // enableBtnDelete={false}
            BtnSave={({ handleSave, view, emptyObject }) => (
                <Button 
                    style={{ width: '100%' }} 
                    variant="contained" 
                    color="success"
                    size="medium"
                    onClick={handleSave}>
                        {view === 'new' && 'Abrir Pedido'}
                        {view === 'edit' && 'Salvar'}
                </Button>
            )}
            titleBtnCancel="Voltar"
            saveContinueForm={true}

            emptyObject={{
                cliente_id: '',
                tipo: 'P',
                desconto: 0,
                lat: position.lat,
                lng: position.lng
            }}
            fields={[
                { name: 'id', label: 'Dados', classBody: 'min-width' },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td>
                        <Typography>
                            ID: {item.id} <br />
                            <strong>Código:</strong> {item.codigo} <br />
                            <strong>Cliente:</strong> {item.cliente.razao_social} <br />
                            {item.nome_fantasia} <br />
                            {item.data} <br />
                            <strong>Status:</strong> 
                            {Util.getStatusSpan(item.status)}
                        </Typography>
                    </td>
                </>
            )}
            validation={(yup) => {
                return {
                    cliente_id: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}

