import React from 'react';
import {
    BrowserRouter as Router,
    Switch
  } from "react-router-dom";
import { AuthProvider } from 'src/context/auth';
import RouterPrivate from './RouterPrivate';

//   Pages
import Home from 'src/pages/Home';
import Login from 'src/pages/Login';
import Clients from 'src/pages/Clients';
import Fornecedores from 'src/pages/Fornecedores';
import TablePrices from 'src/pages/TablePrices';
import Produtos from 'src/pages/Produtos';
import Orders from 'src/pages/Orders';
import Profile from 'src/pages/Profile';
import Help from 'src/pages/Help';
import Agendamentos from 'src/pages/Agendamentos';
import { HelperProvider } from 'src/context/helper';
import Relatorios from 'src/pages/Relatorios';
import ProdutosMaisVendidos from 'src/pages/Relatorios/ProdutosMaisVendidos';
import Clientes from 'src/pages/Relatorios/Clientes';
import UltimasVendas from 'src/pages/Relatorios/UltimasVendas';
import Vendas from 'src/pages/Relatorios/Vendas';


export default function MainRouter() {   

    const routes = [
        {
            path: '/login',
            component: Login,
            isPrivate: false,
        },
        {
            path: '/',
            component: Home,
            exact: true,
            isPrivate: true,
        },
        {
            path: '/visita',
            component: Agendamentos,
            isPrivate: true,
        },
        {
            path: '/clients',
            component: Clients,
            isPrivate: true,
        },
        {
            path: '/fornecedores',
            component: Fornecedores,
            isPrivate: true,
        },
        {
            path: '/products',
            component: Produtos,
            isPrivate: true,
        },
        {
            path: '/relatorios',
            component: Relatorios,
            isPrivate: true,
        },
        {
            path: '/relatorios-vendas',
            component: Vendas,
            isPrivate: true,
        },
        {
            path: '/relatorios-ultimas-vendas',
            component: UltimasVendas,
            isPrivate: true,
        },
        {
            path: '/relatorios-clientes',
            component: Clientes,
            isPrivate: true,
        },
        {
            path: '/relatorios-produtos',
            component: ProdutosMaisVendidos,
            isPrivate: true,
        },
        {
            path: '/orders',
            component: Orders,
            isPrivate: true,
        },
        {
            path: '/table-prices',
            component: TablePrices,
            isPrivate: true,
        },
        {
            path: '/profile',
            component: Profile,
            isPrivate: true,
        },
        {
            path: '/help',
            component: Help,
            isPrivate: true,
        }
        
    ];

    return (
        <>
            <AuthProvider values={{ isLogged: false }}>
                <HelperProvider values={{ }}>
                    <Router>
                        <Switch>
                            {routes.map((route, idx) => (
                                <RouterPrivate key={idx} {...route} />
                            ))}
                        </Switch>
                    </Router>
                </HelperProvider>
           </AuthProvider>
        </>
    );
}

