import { Avatar, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';

import AuthContext from 'src/context/auth';


export default function Profile() {

    const { user } = useContext(AuthContext);

    return (
        <div id="crud">
            <div id="content-title">
                <h5>
                    Perfil
                </h5>
            </div>
            <div id="content">
                <Grid container>
                    <Grid item xs={12}>
                        <Avatar 
                            style={{ display: 'block', margin: '0 auto', width: '70px', height: '70px' }}
                            alt="Travis Howard" 
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrZNG2V1kv_IH_8aTfCrLyEYKVDuCeuKoHaQ&usqp=CAU" 
                                                        
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography>
                            {user.name} 
                        </Typography>
                        <Typography>
                            E-mail: {user.email} <br />
                            Permissão: {user.role === 1 ? "Administrador" : "Vendas"} <br />
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}