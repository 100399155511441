import React from 'react';
import { Grid, Button } from '@mui/material';

export default function FormButtons(props) {
    return (
        <Grid container spacing={2}>
            {props.enableBtnSave && 
                <Grid item xs={6}>
                    {props.BtnSave ? 
                        <props.BtnSave {...props} /> : 
                        <Button 
                            style={{ width: '100%' }} 
                            variant="contained" 
                            color="success"
                            size="medium"
                            onClick={props.handleSave}>
                                {props.titleBtnSave}
                        </Button>
                    }
                </Grid>
            }
            {props.enableBtnCancel && 
                <Grid item xs={6}>
                    <Button 
                        style={{ width: '100%' }} 
                        variant="contained" 
                        color="warning" 
                        size="medium"
                        onClick={props.handleCancel}>
                            Cancelar
                    </Button>
                </Grid>
            }
        </Grid>
    );
}

FormButtons.defaultProps = {
    enableBtnSave: true,
    enableBtnCancel: true,
    titleBtnSave: 'Salvar',
    BtnSave: null,
};