import React, { useContext, useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';

import { InputTextarea } from 'src/components/FormHelper/Input';
import Form from 'src/components/Form';
import HelperContext from 'src/context/helper';
import { Select } from '../FormHelper/InputML';

const FormWrapper = ({ status, values, Field, ErrorMessage }) => {

    const [list, setList] = useState([]);

     useEffect(() => {
        if(values && values.list_status_options.length) {
            setList([...values.list_status_options]);
        }
    }, [values.list_status_options]);
    
    return (
        <Row>
            <Col md={4}>
                <Field
                    id="status"
                    label="Status"
                    name="status"
                    list={list}
                    placeholder="Selecione"
                    component={Select}
                    error={<ErrorMessage name="status" />}
                />  
            </Col>
            <Col md={12}>
                <Field
                    id="observacao_status"
                    label="Observação"
                    name="observacao_status"
                    required={status === 'pending' ? true : false}
                    component={InputTextarea}
                    error={<ErrorMessage name="observacao_status" />}
                />
            </Col>
        </Row>
    );
}

export default function AlterarStatus() {

    const [data, setData] = useState(null);
    const { viewAlterStatus, setViewAlterStatus } = useContext(HelperContext);

    async function handleSubmit(values) {

        if(values.status === 'pending' && values.observacao === "") {
            Message.infor("Observação Obrigatória");
            return false;
        }

        await Api('put', `pedidos/${viewAlterStatus}`, '', {
            id: viewAlterStatus,
            status: values.status,
            observacao_status: values.observacao_status
        });        
        Message.success("Status Alterado com Sucesso");
        setViewAlterStatus('');
        
    }

    async function getData() {
        const response = await Api('get', `pedidos/${viewAlterStatus}`, '', {});        
        setData({...response.data});
    }

    useEffect(() => {
        if(viewAlterStatus) {
            getData();
        }
    }, [viewAlterStatus]);

    return (
        <>
            <Modal
                show={viewAlterStatus ? true : false}
                onHide={() => setViewAlterStatus('')}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Alterar Status do Pedido
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data && 
                        <Form
                            data={data}
                            emptyObject={data}
                            enableBtnCancel={false}
                            validation={(yup) => {
                                return null;
                            }}
                            handleSubmit={handleSubmit}
                            FormWrapper={FormWrapper}
                        />
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}