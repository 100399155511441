import React, { useEffect, useState } from 'react';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import Form from 'src/components/Form';
import { Select, InputText, InputTextarea } from 'src/components/FormHelper/Input';
import { Modal, Box, Grid, Table, TableBody } from '@mui/material';

const FormWrapper = ({ data, values, Field, ErrorMessage }) => {

    const [listFormasPagamentos, setListFormasPagamentos] = useState([]);
    const [listTiposPagamentos, setListTiposPagamentos] = useState([]);
    const [position, setPosition] = useState({
        lat: '',
        lng: '',
    })
    function showPosition(position){
        setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
    }

   
    async function getFormasPagamentos() {
        const response = await Api('get', 'forma-pagamento/options', '', {});        
        setListFormasPagamentos(response.data);
    }

    async function getTiposPagamentos() {
        const response = await Api('get', 'itens-tipos-pagamentos/options', '', {
            params: {
                forma_pagamento_id: values.forma_pagamento_id
            }
        });        
        setListTiposPagamentos(response.data);
    }

    useEffect(() => {
        getFormasPagamentos();
    }, []);

    useEffect(() => {
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Navegador não suporta Geolocalização");
        }
    }, []);

    useEffect(() => {
        if(values.forma_pagamento_id) {
            getTiposPagamentos();
        }
    }, [values.forma_pagamento_id])

    return (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Field 
                        required
                        id="forma_pagamento_id"
                        label="Forma de Pagamento"
                        name="forma_pagamento_id"
                        component={Select}
                        list={listFormasPagamentos}
                        error={<ErrorMessage name="forma_pagamento_id" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field 
                        required
                        id="item_tipo_pagamento_id"
                        label="Tipo de Pagamento"
                        name="item_tipo_pagamento_id"
                        component={Select}
                        list={listTiposPagamentos}
                        error={<ErrorMessage name="item_tipo_pagamento_id" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field 
                        required
                        id="desconto"
                        label="Desconto %"
                        name="desconto"
                        component={InputText}
                        list={listTiposPagamentos}
                        error={<ErrorMessage name="desconto" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        component={InputTextarea}
                        rows="10"
                        error={<ErrorMessage name="observacao" />}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Field
                        id="data_visita"
                        label="Data da Próxima Visita"
                        name="data_visita"
                        type="date"
                        component={InputText}
                        rows="10"
                        error={<ErrorMessage name="data_visita" />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        id="hora_visita"
                        label="Horário da Visita"
                        name="hora_visita"
                        type="time"
                        component={InputText}
                        rows="10"
                        error={<ErrorMessage name="hora_visita" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        id="observacao_visita"
                        label="Observação da Visita"
                        name="observacao_visita"
                        component={InputTextarea}
                        rows="10"
                        error={<ErrorMessage name="observacao_visita" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    Lat: {position.lat} - Lng: {position.lng}
                </Grid>
            </Grid>
            <Grid>
                <Grid item xs={12}>
                    <Table hover striped>
                        <TableBody>
                            <tr>
                                <td>Total</td>
                                <td style={{  textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(data.total)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Desconto</td>
                                <td style={{  textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(parseFloat(data.total) * parseFloat(values.desconto) / 100)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '16px' }}>
                                    <strong>
                                        Total com Desconto
                                    </strong>
                                </td>
                                <td style={{ fontSize: '16px', textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(parseFloat(data.total) - (parseFloat(data.total) * parseFloat(values.desconto) / 100))}
                                    </strong>
                                </td>
                            </tr>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    );
}

export default function FinishOrder({ pedido_id, showFinish, setShowFinish, handleEdit }) {

    const [data, setData] = useState(null);
    const [position, setPosition] = useState({
        lat: '',
        lng: '',
    })
    function showPosition(position){
        setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
    }

    useEffect(() => {
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Navegador não suporta Geolocalização");
        }
    }, []);

    async function handleSubmit(values) {
        await Api('put', `pedidos_vendedores/${pedido_id}`, '', {
            id: pedido_id,
            forma_pagamento_id: values.forma_pagamento_id,
            item_tipo_pagamento_id: values.item_tipo_pagamento_id,
            desconto: values.desconto,
            observacao_status: values.observacao,
            data_visita: values.data_visita,
            hora_visita: values.hora_visita,
            observacao_visita: values.observacao_visita,
            lat: position.lat,
            lng: position.lng
        });        
        Message.success("Pedido Finalizado com Sucesso");
        handleEdit({id: pedido_id});
        setShowFinish(false);
    }

    async function getData() {
        
        if(pedido_id) {
            const response = await Api('get', `pedidos/${pedido_id}`, '', {});        
            setData(response.data);
        }
        
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        getData();
    }, [pedido_id]);

    return (
        <>
           <Modal
                open={showFinish}
                onClose={() => setShowFinish(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <a style={{ position: 'absolute', top: '12px', right: '35px', fontSize: '20px', fontWeight: 'bold' }} href onClick={() => setShowFinish(false)}>X</a>
                    <Form 
                        data={data}
                        emptyObject={data}
                        enableBtnCancel={false}
                        validation={(yup) => {
                            return null;
                        }}
                        handleSubmit={handleSubmit}
                        FormWrapper={FormWrapper}
                    />
                </Box>
            </Modal>
        </>
    )
}